// Migrated to script setup
<template>
  <b-modal
    ref="translationModal"
    v-model="show"
    :hide-header="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    body-class="no-padding"
    centered
    sticky-footer
    modal-class="translation-modal"
    :size="isHtml ? 'sxl' : 'lg'"
    @hide="onHide"
  >
    <template #default>
      <ModalCloseButton
        align="border"
        shadow
        @click="onHide"
      />
      <Loading v-if="loading" />
      <Error v-else-if="failed" />
      <div
        v-else
        class="cms-content pb-6 mb-2"
      >
        <div class="flex flex-wrap -mx-4">
          <div
            class="w-12/12 xl:w-12/12 px-4"
            :class="[hasHtml ? '3xl:w-4/12' : '3xl:w-6/12 sm:w-6/12']"
          >
            <h4>
              {{ $t('translations.originalText') }}
            </h4>
          </div>
          <div
            v-if="hasHtml"
            class="hidden flex-col sm:flex xl:w-6/12 3xl:w-4/12 px-4"
          >
            <h4>
              {{ $t('translations.preview') }}
            </h4>
          </div>
          <div
            class="w-12/12 px-4"
            :class="[hasHtml ? '3xl:w-4/12 xl:w-6/12' : '3xl:w-6/12 xl:w-12/12']"
          >
            <h4>
              {{ $t('translations.translation') }}
            </h4>
          </div>
        </div>
        <div
          v-for="translate in translations"
          :key="translate?.translationKey"
          class="flex flex-wrap -mx-4 mb-12"
        >
          <div
            class="w-12/12 px-4"
            :class="[isHtml(translate) ? '3xl:w-4/12' : '3xl:w-6/12 xl:w-6/12']"
          >
            <div v-html="translate?.originalText" />
          </div>
          <div
            v-if="isHtml(translate)"
            class="hidden flex-col sm:flex xl:w-6/12 3xl:w-4/12 px-4"
          >
            <div v-html="translate?.content" />
          </div>
          <div
            class="w-12/12 px-4"
            :class="[isHtml(translate) ? '3xl:w-4/12 xl:w-12/12' : '3xl:w-6/12 xl:w-6/12']"
          >
            <div class="form-group h-full">
              <textarea
                id="content"
                v-model="translate.content"
                class="form-control max-h-full"
                name="content"
              />
            </div>
            <code
              class="block text-right"
              style="font-size:.6rem"
            >{{ translate?.translationKey }}</code>
            <div
              v-if="translate?.machineTranslated"
              class="badge badge-md light-blue-bg text-white"
            >
              Machine Translated
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="flex flex-wrap -mx-4 w-full items-center">
        <div class="w-6/12 justify-start">
          <div class="flex">
            <button
              class="btn grow text-black"
              @click="onHide"
            >
              {{ $t('formCloseButton') }}
            </button>
            <button
              class="btn grow text-black"
              :disabled="!showUndo"
              @click="regret"
            >
              {{ $t('formRegret') }}
            </button>
          </div>
        </div>
        <div class="w-6/12 justify-end text-right">
          <div
            v-if="!htmlIsValid"
            class="badge badge-md bright-red-bg text-white"
          >
            Invalid HTML
          </div>
          <div class="flex ml-4">
            <SubmitButton
              class="btn grow green-bg text-white"
              :disabled="!htmlIsValid"
              :loading="requestLoading"
              @click="onSave"
            >
              {{ $t('bookSaveChanges') }}
            </SubmitButton>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import events from '@layers/web/constants/events.js'

const { $event } = useNuxtApp()

const localeStore = useLocaleStore()
const requestLoading = ref(false)
const loading = ref(true)
const show = ref(false)
const failed = ref(false)
const translationType =  ref(null)
const translations = ref([])
const originalContent = ref('')
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const translation = ref(null)
// const translationKey = ref(null) never used
const translationModal = ref(null)
const content = ref('')

/*
  htmlIsValid () {
    if (!this.isHtml) {
      return true
    }
    return stringIsValidHtml(content.value)
  },
    */
const hasHtml = computed(() => {
  return stringContainsHtml(content.value)
})

const showUndo = computed(() => {
  return content.value !== originalContent.value
})

onMounted(() => {
  $event.$on(events.showTranslationModal, toggle)
})

onBeforeUnmount(() => {
  $event.$off(events.showTranslationModal, toggle)
})

const toggle = ({ type, name }) => {
  reset()
  loading.value = true
  requestLoading.value = false
  show.value = !show.value
  translationType.value = type

  const list = Array.isArray(name) ? name : name.split('|')
  translations.value = list.map(item => ({
    translationKey: show.value ? item : '',
    content: '',
  }))

  if (show.value) {
    onShow()
  } else {
    onHide()
  }
}

const htmlIsValid = (content) => {
  if (!isHtml(content)) {
    return true
  }
  return translations.value
    .map(({ content }) => stringIsValidHtml(content))
    .filter(Boolean)
    .length > 0
}

const isHtml = () => {
  return translations.value
    .map(({ content }) => stringContainsHtml(content))
    .filter(Boolean)
    .length > 0
}

const reset = () => {
  translationType.value = null
  failed.value = false

  translations.value = []
}

const onShow = async () => {
  if (![translationTypes.translation, translationTypes.i18n].includes(translationType.value)) {
    failed.value = true
    loading.value = false
    return
  }

  const results = await Promise.all(
    translations.value.map(({ translationKey }) => localeStore.fetchTranslationKey({ key: translationKey, type: translationType.value }))
  )
  translations.value = results.map((result) => {
    if (result) {
      const content = (result?.translationText || '').replaceAll('\r', '\n')
      return {
        ...result,
        content,
        originalContent: content,
      }
    } else {
      failed.value = true
      return null
    }
  })

  loading.value = false
}

const onHide = () => {
  show.value = false
  reset()
}

const regret = () => {
  translations.value = translations.value.map(t => ({
    ...t,
    content: t.originalContent,
  }))
}

const onSave = async () => {
  if (translations.value.length === 0) {
    return
  }
  requestLoading.value = true

  const payloads = translations.value.map(translation => ({
    ...(translation || {}),
    machineTranslated: false,
    translatedText: translation.content,
  }))
  const result = await Promise.all(
    payloads.map(payload => localeStore.updateTranslation({ payload, type: translationType.value }))
  )

  const success = result
    .map(res => res?.status === 'updated' && !!res?.new)
    .filter(Boolean)
    .length > 0

  if (success) {
    onHide()
  } else {
    failed.value = true
  }

  requestLoading.value = false
}
</script>
