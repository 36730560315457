// Migrated changes to script setup
<template>
  <div v-if="showTranslationTool && !$isSol">
    <Fab
      :color="translationToolsActive ? 'green' : 'orange'"
      :icon="icons.language"
      position="left"
    >
      <template #header>
        <h4 class="mb-0">
          Translations
        </h4>
      </template>
      <template #body="{ onToggle }">
        <button
          class="text-left btn-translation-toggle btn text-black block"
          @click="toggleTranslationTools"
        >
          <fa
            class="mr-1"
            :icon="!translationToolsActive ? icons.hide : icons.show"
          />
          <span>
            {{ translationToolsActive ? 'Hide' : 'Show' }} tools
          </span>
          <small class="inline-block ml-2">
            [Ctrl + Z]
          </small>
        </button>
        <button
          class="text-left btn-translation-toggle btn text-black block"
          @click="() => { toggleFullPageModal(); onToggle() }"
        >
          <fa
            class="mr-1"
            :icon="icons.cog"
          />
          <span>
            Page settings
          </span>
        </button>
      </template>
    </Fab>
    <SingleTranslationModal />
    <FullPageTranslationModal />
  </div>
</template>

<script setup>
import { faEye, faEyeSlash, faLanguage, faCog } from '@fortawesome/pro-regular-svg-icons'

import events from '@layers/web/constants/events.js'

const { $event: event } = useNuxtApp()

const localeStore = useLocaleStore()
const { fullTranslationSuiteActive, translationToolsActive } = storeToRefs(localeStore)

const rootStore = useRootStore()
const { showTranslationTool } = storeToRefs(rootStore)

const icons = {
  language: faLanguage,
  show: faEye,
  hide: faEyeSlash,
  cog: faCog,
}
const listenersActive = ref(false)

watch(() => showTranslationTool.value, (v) => {
  if (v) {
    initListeners()
  }
})

watch(() => fullTranslationSuiteActive.value, (v) => {
  const root = document.documentElement
  const className = '--translations-active'
  if (v) {
    initListeners()
    root.classList.add(className)
  } else {
    root.classList.remove(className)
  }
})

onMounted(() => {
  initListeners()

  const a = useCookie('a')
  if (a && a?.translationtools) {
    rootStore.SET_TRANSLATIONTOOLS(a.translationtools)
  }
})

onBeforeUnmount(() => {
  killListeners()
})

const initListeners = () => {
  if (!showTranslationTool || isSol()) {
    killListeners()
    return
  }
  if (listenersActive.value) {
    return
  }

  window.addEventListener('keydown', keyListener)
  window.addEventListener('mousedown', clickListener)
  listenersActive.value = true
}

const killListeners = () => {
  window.removeEventListener('keydown', keyListener)
  window.removeEventListener('mousedown', clickListener)
  listenersActive.value = false
}

const keyListener = (e) => {
  const isAlsoCtrl = e?.ctrlKey
  const isKeyZ = e?.key === 'z' || e?.code === 'KeyZ' || e?.keyCode === 90
  if (showTranslationTool && isAlsoCtrl && isKeyZ) {
    toggleTranslationTools()
  }
}

const clickListener = (e) => {
  if (fullTranslationSuiteActive.value && e?.target) {
    let payload = null
    const evaluator = (key, target) => {
      if (target?.attributes?.[key]?.value) {
        return target?.attributes?.[key]?.value
      } else if (target?.parentNode?.attributes?.[key]?.value) {
        return target?.parentNode?.attributes?.[key]?.value
      }
      return null
    }
    if (evaluator('data-translation', e.target)) {
      payload = {
        type: translationTypes.translation,
        name: evaluator('data-translation', e.target),
      }
    } else if (evaluator('data-i18n', e.target)) {
      payload = {
        type: translationTypes.i18n,
        name: evaluator('data-i18n', e.target),
      }
    }

    if (payload) {
      event.$emit(events.showTranslationModal, payload)
    }

    const ignore = ['.translation-modal']
    if (!e.target.closest(ignore.join(','))) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
}

const toggleFullPageModal = () => {
  event.$emit(events.showFullPageTranslationModal)
}

const toggleTranslationTools = () => {
  localeStore.TOGGLE_TRANSLATION_TOOLS(!translationToolsActive.value)
}
</script>

<style lang="scss">
.btn-translation-toggle {
  svg.svg-inline--fa {
    width: 24px;
  }
}
html.--translations-active {
  .translation-modal {
    z-index: 999999;
  }
  [data-translation],
  [data-translation="*"],
  [data-i18n],
  [data-i18n="*"] {
    outline: 5px dashed green !important;
    cursor: pointer !important;
    padding: 10px;
    pointer-events: all;

    &:hover {
      outline-color: theme('colors.orange.DEFAULT') !important;
    }
  }

  a {
    cursor: context-menu !important;
  }
}
</style>
