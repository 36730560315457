//  Migrated to Script Setup
<template>
  <b-modal
    ref="translationFullModal"
    v-model="show"
    :hide-header="true"
    :no-close-on-backdrop="false"
    :no-close-on-esc="false"
    body-class="no-padding"
    centered
    sticky-footer
    modal-class="translation-modal"
    :size="loading || translationNames.length === 0 ? 'md' : 'lg'"
    :hide-footer="loading || translationNames.length === 0"
  >
    <template #default>
      <div class="text-right">
        <fa
          icon="times"
          @click="toggle"
        />
      </div>
      <template v-if="loading">
        <Loading />
      </template>
      <template v-else-if="i18nNames.length > 0 || translationNames.length > 0">
        <div
          v-for="name in translationNames"
          :key="name"
          class="fullpage-translation-item mb-2 pb-4"
        >
          <label class="flex grow flex-row justify-between items-center font-mono">
            <span class="font-monospace">
              {{ name }}
            </span>
            <span
              v-if="!data?.[`${name}_translationkey`]"
              class="small text-bright-red"
            >Key missing. Currently unable to translate.</span>
          </label>
          <div :data-translation="data?.[`${name}_translationkey`]">
            <span v-if="data?.[name]">
              {{ data?.[name] }}
            </span>
            <span
              v-else
              class="font-italic"
            >[Empty]</span>
          </div>
        </div>
        <div
          v-for="name in i18nNames"
          :key="name"
          class="fullpage-translation-item mb-2 pb-4"
        >
          <label class="font-monospace">
            i18n-t.{{ name }}
          </label>
          <div :data-i18n="name">
            <span v-if="$t(name)">
              {{ $t(name) }}
            </span>
            <span
              v-else
              class="font-italic"
            >[Empty]</span>
          </div>
        </div>
      </template>
      <div
        v-else
        class="text-center w-full"
      >
        <fa
          class="mb-6"
          :icon="iconMagnifyingGlass"
          size="4x"
        />
        <h5 class="w-full mb-12">
          No page-wide keys for this page
        </h5>
      </div>
    </template>
    <template #modal-footer>
      <div class="flex flex-wrap -mx-4 w-full items-center">
        <div class="w-6/12 justify-start">
          <div class="flex">
            <button
              class="btn grow text-black"
              @click="onHide"
            >
              {{ $t('formCloseButton') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import Loading from '@layers/web/components/alerts/Loading'

import events from '@layers/web/constants/events.js'

//add mitt for envet bus
const { $event } = useNuxtApp()

const localeStore = useLocaleStore()
const { locale, pageWideTranslations } = storeToRefs(localeStore)

const tripStore = useTripStore()
const { trip } = storeToRefs(tripStore)

const translationFullModal = ref()
const show = ref(false)
const loading = ref(true)
const data = ref(null)
const translationNames = ref([])
const i18nNames = ref([])
const iconMagnifyingGlass = faMagnifyingGlass

onMounted(() => {
  $event.$on(events.showFullPageTranslationModal, toggle)
})

onBeforeUnmount(() => {
  $event.$off(events.showFullPageTranslationModal, toggle)
})

const router = useRouter()

const init = (async () => {
  loading.value = true
  data.value = null
  translationNames.value = []
  i18nNames.value = []

  const { name, params } = router.currentRoute.value

  if (name === 'category-slug') {
    try {
      const { data: categoryData } = await apiFetch(`/${locale.value}/category/${params?.slug}`)
      data.value = categoryData
      translationNames.value = [...translationNames.value, 'title', 'category_url']
    } catch {
      // do nothing
    }
  }

  const id = trip.value?.id
  if (id && name === 'triptype-slug-tab') {
    try {
      const { data: tripData } = await apiFetch(`/${locale.value}/triptype?id=${id}`)
      data.value = tripData
      translationNames.value = [...translationNames.value, 'url', 'html_title', 'name', 'short_preamble']
    } catch {
      // do nothing
    }
  }

  if (pageWideTranslations.value) {
    i18nNames.value = [
      ...(i18nNames.value || []),
      ...(pageWideTranslations.value?.[translationTypes.i18n] || []),
    ]
    translationNames.value = [
      ...(translationNames.value || []),
      ...(pageWideTranslations.value?.[translationTypes.translation] || []),
    ]
    if (data.value || pageWideTranslations.value?.translationData) {
      data.value = {
        ...(data.value || {}),
        ...(pageWideTranslations.value?.translationData || {}),
      }
    }
    console.info(pageWideTranslations.value, i18nNames.value)
  }
  if (i18nNames.value.length > 0 || (translationNames.value.length > 0 && data.value)) {
    localeStore.TOGGLE_TRANSLATION_TOOLS(true)
  }
  loading.value = false
})

const toggle = (() => {
  show.value = !show.value

  if (show.value) {
    init()
  }
})

const onHide = (() => {
  show.value = false
})
</script>

<style lang="scss">
.fullpage-translation-item {
  .cms__inner{
    margin-left: 50px !important;
  }
}
</style>
